<template>
    <section>
      <iHeader v-show="fromPage" @back-event="closeWebview"/>
        <div class="m-map">
            <div class="content">
                <div class="searchDiv" v-show="!fromPage">
                    <svg class="icon" aria-hidden="true" @click.stop="closeWebview()">
                        <use xlink:href="#icon-fanhui"></use>
                    </svg>
                    <div class="search background-color-FFF">
                        <input @blur="inputFilter" type="text" id="pickerInput"  placeholder="请输入关键字" v-model="searchKey">
                    </div>
                </div>
                <div ref="wrapper">
                    <div id="container" class="venueMap" :style="{height: wrapperHeight + 'px' }"></div>
                </div>
            </div>
        </div>
      <div v-show="!fromPage" class="largeBtn" @click="submitVenue"> 确定 </div>
    </section>
</template>
<script>
    import map from './map.js'
    export default {
        name: 'map',
        mixins: [map],
        data () {
            return {
                wrapperHeight: 0,
                searchKey: '',
                selectedVenue: [],
                maxSelect: 0,
                fromPage: false,
            }
        },
        mounted () {
            this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight);
            this.getAddress()
        },
        created () {
            this.selectedVenue = this.$SmartStorage.get('venueList') || []
            this.maxSelect = this.$SmartStorage.get('maxSelect') || 0
            let fromPage = this.$route.query;
            this.fromPage = fromPage.fromPage ? fromPage.fromPage : false;
        },
        methods:{
            closeWebview() {
                this.$router.back()
            },
            // 获取地址
            getAddress() {
                let addres = this.$route.query
                this.lat = addres.lat
                this.lng = addres.lng
                this.initMap()
            },
            async submitVenue () {
                if(!this.searchKey){
                    this.$toast('请输入关键字查询！')
                    return;
                }
                if(this.selectedVenue && (this.selectedVenue.length > this.maxSelect)) {
                    this.$toast('最多可选择'+this.maxSelect+"家供应商")
                    return;
                }
                if (!this.addressInfo.province && !this.addressInfo.city && !this.addressInfo.region || !this.addressInfo.address) {
                    this.$toast('未能查询到您所选酒店地址信息，请更换关键词重新查询')
                    return
                }
                let params = {
                    amapId: this.addressInfo.id,
                    name: this.addressInfo.name,
                    address: this.addressInfo.address,
                    province: this.addressInfo.province,
                    city: this.addressInfo.city,
                    region: this.addressInfo.region
                }
                let response = await this.$service.CheckMapSelection(params)
                if (response.success) {
                    switch (response.content.type) {
                        case 1:
                        case 2:
                            this.$toast(response.content.type == 2 ? '代付费人员暂时未能覆盖该区域/酒店，请直接刷卡结算酒店费用' : '您选择的酒店为黑名单酒店，请重新选择酒店');
                            break;
                        case 3:
                        case 4:
                            if (response.content.isOffline && ['lilly', 'roche','ipsen'].includes(this.$cookie.get("tenant"))) {
                                this.$dialog({
                                    message:
                                        "您选择的酒店可能产生上会人员差旅费用，请确认！",
                                    showCancelButton: true,
                                    confirmButtonText: "确定",
                                    cancelButtonText: "取消"
                                }).then(action => {
                                    if (action == "confirm") {
                                        this.updateVenue(response.content)
                                    }
                                });
                            } else {
                                this.updateVenue(response.content)
                            }
                            break;
                    }
                }
            },
            updateVenue(item) {
                let isRepeat = false
                //若返回的supplier为空,则不考虑supplierId来判断重复
                isRepeat = this.selectedVenue.some(ele => {
                    return ele.amapId === this.addressInfo.id || (item.supplier && ele.supplierId === item.supplier.supplierId)
                })
                if (isRepeat) {
                    this.$dialog.alert({message:'该酒店已被选择，请重新搜索其他酒店！'})
                    return
                }
                this.$SmartStorage.remove('selectedVenueId')
                if (item.type == 3) {
                    this.$SmartStorage.set('venueList', [{
                        amapId: this.addressInfo.id,
                        name: this.addressInfo.name,
                        address: this.addressInfo.address,
                        province: this.addressInfo.province,
                        city: this.addressInfo.city,
                        region: this.addressInfo.region
                    }])
                    if (!this.$SmartStorage.get('orderId')) {
                      this.$router.push('/addDemand?demandType=' + this.$SmartStorage.get('demandType'));
                    } else {
                      this.$router.push('/requirementsListOrder');
                    }
                } else {
                    this.selectedVenue.push({
                        amapId: item.supplier.amapId,
                        supplierId: item.supplier.supplierId,
                        name: item.supplier.name,
                        address: item.supplier.address
                    })
                    this.$SmartStorage.set('venueList', this.selectedVenue)
                    if (!this.$SmartStorage.get('orderId')) {
                      this.$router.push('/addDemand?demandType=' + this.$SmartStorage.get('demandType'));
                    } else {
                      this.$router.push('/requirementsListOrder');
                    }
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .m-map{
        position: relative;
        width: 100%;
        height: 100%;
        .content{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            .searchDiv {
                position: fixed;
                z-index: 2;
                background: #fff;
                width: calc(100% - 0.4rem);
                left: .1rem;
                border-radius: 10px;
                display: flex;
                top: 0.2rem;
                padding: 0.05rem 0.1rem;
                justify-content: center;
                align-items: center;
                svg {
                    width: 0.2rem;
                    height: 0.2rem;
                    color: #00000080;
                }
                .search {
                    width: calc(100% - 0.4rem);
                    input{
                        width: 100%;
                        box-sizing: border-box;
                        padding: .1rem .05rem;
                        outline: none;
                    }
                }
            }
            .venueMap {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
            }
        }
    }
    .largeBtn {
      position: absolute;
    }
</style>
<style lang="scss">
    .m-map {
        .amap-ui-poi-picker-sugg-container.dock-bottom {
            border-radius: 10px;
            margin-top: 20px;
            width: 100% !important;
            text-align: left;
            min-width: auto !important;
            border: 1px solid #ddd;
            max-height: 300px;
            padding: 10px;
            left: 0px !important;
            li.sugg-item {
                display: flex;
                flex-direction: column;
                padding-left: .3rem !important;
                border-bottom: 1px solid #f2f3f5 !important;
                span.sugg-name {
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }
        }
    }
</style>
