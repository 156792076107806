import AMapLoader from '@amap/amap-jsapi-loader';
export default {
    data () {
        return {
            map:null,
            lat: null,
            lng: null,
            addressInfo: {
                province: '',
                city: '',
                region: '',
                id: '',
                name: '',
                address: ''
            }
        }
    },
    methods: {
        initMap(){
            let _this = this
            AMapLoader.load({
                key:"4baaccadcc516ab6c9afd7a7d9963c7f",// 申请好的Web端开发者Key，首次调用 load 时必填
                version:"2.0",// 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins:['AMap.ToolBar'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
                AMapUI: {
                    plugins: ['misc/PositionPicker','misc/PoiPicker']
                },
            }).then((AMap)=>{
                let mapConfig = {
                    zoom: 16
                }
                if (_this.lat && _this.lng) {
                    mapConfig.center = [_this.lng, _this.lat]
                }
                _this.map = new AMap.Map("container",mapConfig);
                AMapUI.loadUI(['misc/PositionPicker','misc/PoiPicker'], (PositionPicker,PoiPicker) => {
                    let poiPicker = new PoiPicker({
                        input: 'pickerInput'
                    });
                    this.poiPickerReady(poiPicker);
                    // 启用工具条
                    // AMap.plugin(['AMap.ToolBar'], function () {
                    //     _this.map.addControl(new AMap.ToolBar({
                    //         position: 'RB'
                    //     }))
                    // })
                    // 创建地图拖拽
                    let positionPicker = new PositionPicker({
                        mode: 'dragMap', // 设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                        map: _this.map // 依赖地图对象
                    })
                    // 拖拽完成发送自定义 drag 事件
                    positionPicker.on('success', positionResult => {
                        // 过滤掉初始化地图后的第一次默认拖放
                        if (!_this.dragStatus) {
                            _this.dragStatus = true
                        } else {
                            _this.addressInfo.province = positionResult?.regeocode?.addressComponent?.province
                            _this.addressInfo.city = positionResult?.regeocode?.addressComponent?.city
                            _this.addressInfo.region = positionResult?.regeocode?.addressComponent?.district
                            _this.$emit('drag', positionResult)
                        }
                    })
                    // 启动拖放
                    positionPicker.start()
                })
            }).catch(e=>{
                console.log(e);
            })
        },
        poiPickerReady(poiPicker) {
            window.poiPicker = poiPicker;
            let marker = new AMap.Marker();
            let infoWindow = new AMap.InfoWindow({
                offset: new AMap.Pixel(0, -20)
            });
            let _this = this
            //选取了某个POI
            poiPicker.on('poiPicked', function(poiResult) {
                _this.searchKey = poiResult.item.name
                _this.addressInfo.id = poiResult.item.id
                _this.addressInfo.name = poiResult.item.name
                _this.addressInfo.location = poiResult.item.location
                _this.addressInfo.address = typeof(poiResult.item.address)=='string' ? poiResult.item.address : ''
                let poi = poiResult.item,
                    info = {
                        name: poi.name,
                        location: poi.location.toString(),
                        address: poi.address
                    };
                marker.setMap(_this.map);
                infoWindow.setMap(_this.map);
                marker.setPosition(poi.location);
                infoWindow.setPosition(poi.location);
                infoWindow.setContent('位置信息: <p>名称:'+info.name+'</p>'+'<p>地址:'+info.address+'</p>');
                infoWindow.open(_this.map, marker.getPosition());
                _this.map.setCenter(marker.getPosition());
            });
            poiPicker.onCityReady(function() {
                // poiPicker.suggest('美食');
            });
        },
    }
}
